<template>
  <!--
    Used to display the response / output of a command.
  -->
  <div class="commandDialogLogging">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
          <select
            v-model="selectedCommand"
            class="form-control"
          >
            <option
              v-for="(command, index) in commands"
              :key="`option${ index }`"
              :value="command"
            >
              {{ command.name }}
            </option>
          </select>
        </div>
        <div class="col-12 col-sm-3 col-md-3 col-lg-2 mb-3">
          <button
            v-if="readyToExecute"
            class="btn btn-primary btn-block"
            @click="executeCommand"
          >
            <font-awesome-icon
              class="mr-1"
              icon="download"
            />
            <span>
              {{ $t('load') }}
            </span>
          </button>
          <button
            v-else
            class="btn btn-primary btn-block"
            disabled="disabled"
          >
            <font-awesome-icon
              class="mr-1"
              icon="download"
            />
            <span>
              {{ $t('load') }}
            </span>
          </button>
        </div>
        <div class="col-12 col-sm-3 col-md-5 col-lg-6 mb-3">
          <span
            v-if="lastRequest"
            v-tooltip="`${ lastRequest | dateTime_fromNow }`"
            data-toggle="tooltip"
            data-placement="top"
          >{{ $t('lastRequest') }}: {{ lastRequest | dateTime_dateTime }}</span>
          <button
            v-if="lastRequest"
            class="btn btn-primary btn-sm ml-2"
            @click="openModal(response)"
          >
            Complete Log
          </button>
        </div>
        <div class="col-12">
          <pre
            ref="loggingBox"
            class="p-4 border pre"
          >
            <LoadingPlaceholder
              v-if="loading"
              class="p-0 m-0"
/>
              <!-- TODO: optimise => v-else throws error -->
            <span v-if="!loading">{{ response }}</span>
          </pre>
        </div>
      </div>
    </template>
    <SweetModal
      ref="message"
      :title="$t('message')"
      blocking
      class="overflowHidden"
    >
      <div
        v-if="response"
        style="max-height: 550px; overflow: scroll;"
      >
        <div
          style="white-space: pre-wrap;"
        >
          {{ selectedMessage }}
        </div>
      </div>
      <button
        slot="button"
        class="btn btn-secondary float-right mb-3"
        @click="$refs.message.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: "CommandDialogLogging",
  components: {
    SweetModal
  },
  mixins: [
    dateTimeMixin
  ],
  props: {
    commands: {
      type: Array,
      required: true
    },
    deviceId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      selectedCommand: null,
      response: null,
      loading: false,
      lastRequest: null,
      selectedMessage: null
    }
  },
  computed: {
    readyToExecute () {
      if (!this.commands) {
        return false;
      }
      if (!this.selectedCommand) {
        return false;
      }
      return true;
    }
  },
  mounted () {
    this.selectedCommand = this.commands[0];
  },
  methods: {
    openModal (val) {
      this.selectedMessage = val
      this.$refs.message.open();
    },
    executeCommand () {
      this.loading = true;
      this.lastRequest = Date.now();
      this.$nextTick(() => {
        window.$('[data-toggle="tooltip"]').tooltip();
      });

      let command = this.commands.find((command) => {
        return command.name === this.selectedCommand.name
      });

      let commandRequest = {
        'Command': command.name,
        'Args': ''
      };

      this.axios.post(`/CentralDeviceManagement/ExecuteCommand?deviceUuid=${ this.deviceId }`, commandRequest)
        .then((response) => {
          this.response = response.data.output;
        })
        .finally(() => {
          this.loading = false;

          //Scroll to bottom
          this.$nextTick(function () {
            let loggingBox = this.$refs.loggingBox;
            loggingBox.scrollTop = loggingBox.scrollHeight;
          });
        });
    }
  }
}
</script>

<style scoped>
.pre {
  height: 200px;
  overflow: scroll;
  white-space: pre-line;
}
</style>
